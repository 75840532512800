const LOCAL_HOSTNAME = "localhost";

const LOCAL_URL = "http://localhost:4000/api";
const PROD_URL = "https://api.speak-now.ca/api";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const BASE_API_URL = () => {
  const { hostname } = window.location;

  if (hostname.includes(LOCAL_HOSTNAME)) {
    return LOCAL_URL;
  }

  return PROD_URL;
};


export const WEDDING_ID = "8c7e9cd0-34a0-4ba7-b9d8-b924c256fa18"