/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FunctionalComponent, h } from "preact";
import style from "./style.css";

export interface CardProps {
  children: JSX.Element | JSX.Element[];
}

const Card: FunctionalComponent<CardProps> = ({ children }) => {
  return (
    <div class={style.cardWrapper} >
      {children}
    </div>
  )
}

export default Card;