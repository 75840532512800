import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import WeddingService, { WeddingMessagesResponse } from '../../api/wedding';
import Card from '../../components/Card/Card';
import { WEDDING_ID } from '../../config';
import { WeddingMessageModel } from '../../models/wedding';
import style from './style.css';

const Message: FunctionalComponent = () => {
  const [messages, setMessages] = useState<WeddingMessageModel[]>([])
  const [fetchingMessages, setFetchingMessages] = useState<boolean>(false)

  const fetchMessages = (): void => {
    setFetchingMessages(true)

    WeddingService().listWeddingMessages(WEDDING_ID)
      .then((resp: WeddingMessagesResponse): void => {
        setMessages(resp.data)
      })
      .finally(() => {
        setFetchingMessages(false)
      })
  }

  useEffect(() => {
    fetchMessages()
  }, [])

  return fetchingMessages ?
    <div class={style.loadingWrapper}>
      <div>
        <div class={style.loadingBar}></div>
        <div class={style.loadingBar}></div>
        <div class={style.loadingBar}></div>
        <div class={style.loadingBar}></div>
      </div>
    </div>
    : (
      <div class={style.viewWrapper}>
        <div class={style.headerWrapper}>
          <h3>Suggestion Messages to Jon and Kim</h3>
        </div>
        <div class={style.messagesWrapper}>
          {messages.map(msg => {
            return (
              <Card>
                <div>
                  {msg.message}
                </div>
              </Card>
            )
          })}
        </div>
      </div>
    );
};

export default Message;
