import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import WeddingService, { VoteRequest, VoteResponse } from '../../api/wedding';
import Button from '../../components/Button/Button';
import { WEDDING_ID } from '../../config';
import { RoutePath } from '../../models/route';
import { WeddingModel } from '../../models/wedding';
import style from './style.css';

const Vote: FunctionalComponent = () => {
  const [isObjecting, setIsObjecting] = useState<boolean>(false)
  const [isApproving, setIsApproving] = useState<boolean>(false)

  const onSuccess = (resp: VoteResponse): void => {
    const encodedWedding = btoa(JSON.stringify(resp.data))
    route(`${RoutePath.Message}?wedding=${encodedWedding}`)
  }

  const onError = (object: boolean): void => {
    const encodedWedding = btoa(JSON.stringify({
      id: WEDDING_ID,
      totalApprovals: !object ? 1 : 0,
      totalObjections: object ? 1 : 0,
      name: "John and Kim's Wedding"
    } as WeddingModel))
    route(`${RoutePath.Message}?wedding=${encodedWedding}`)
  }

  const handleSubmit = (object: boolean): void => {
    object ? setIsObjecting(true) : setIsApproving(true)

    const payload: VoteRequest = {
      id: WEDDING_ID,
      isObjecting: object
    }

    WeddingService().vote(payload)
      .then(onSuccess)
      .catch(() => onError(object))
      .finally(() => {
        object ? setIsObjecting(false) : setIsApproving(false)
      })
  }

  return (
    <div class={style.viewWrapper}>
      <div class={style.headerWrapper}>
        <img alt="Speak now logo" src="/assets/images/speak-now-logo-small.png" class={style.logo} />
        <h3>or forever hold your peace.</h3>
      </div>
      <div class={style.actionsWrapper}>
        <Button buttonClass={style.topButton} loading={isObjecting} onClick={() => handleSubmit(true)}>I object</Button>
        <Button loading={isApproving} onClick={() => handleSubmit(false)} buttonType="clear">I approve</Button>
      </div>
    </div>
  );
};

export default Vote;
