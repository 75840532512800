/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';

import Button from '../../components/Button/Button';
import { RoutePath } from '../../models/route';
import style from './style.css';

const Home: FunctionalComponent = () => {
  const [displayLoadingScreen, setDisplayLoadingScreen] = useState<boolean>(true)

  const logoImagePath = "/assets/images/speak-now-logo-small.png";

  useEffect(() => {
    setTimeout(() => setDisplayLoadingScreen(false), 1000)
  }, [])

  return !displayLoadingScreen ?
    <div class={style.home}>
      <img alt="Speak now logo" src={logoImagePath} class={style.logo} />
      <div class={style.description}>
        On Speak Now you can anonymously disclose whether you object or support your friends upcoming marriage. They want to know your vote! Speak now or forever hold your peace.
      </div>
      <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button buttonClass={style.continueButton} buttonType="primary" onClick={() => route(RoutePath.Video, true)}>Continue</Button>
      </div>
    </div>
    :
    <div class={style.loaderWrapper}>
      <img alt="Speak now logo" src={logoImagePath} class="loading-logo-small" />
    </div>
};

export default Home;
