/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionalComponent, h } from 'preact';
import Button from '../../components/Button/Button';
import style from './style.css';
import WeddingService from '../../api/wedding';
import { WEDDING_ID } from '../../config';

const Reset: FunctionalComponent = () => {
  const handleResetVotes = () => {
    WeddingService().resetVotes(WEDDING_ID)
  }

  return (
    <div class={style.resetWrapper} >
      <Button onClick={handleResetVotes}>Reset votes</Button>
    </div>
  )
}

export default Reset;
