/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionalComponent, h, Fragment } from 'preact';
import { WeddingModel } from '../../../models/wedding';
import { useEffect, useState } from 'preact/hooks';
import WeddingService, { WeddingResponse } from '../../../api/wedding';
import { WEDDING_ID } from '../../../config';
import style from '../style.css';
import { Bar } from 'preact-chartjs-2';

const Chart: FunctionalComponent = () => {
  const [fetchingWedding, setFetchingWedding] = useState<boolean>(true);
  const [wedding, setWedding] = useState<WeddingModel | undefined>(undefined)

  const initialFetchWedding = () => {
    WeddingService().getWedding(WEDDING_ID)
      .then((resp: WeddingResponse) => setWedding(resp.data))
      .finally(() => setFetchingWedding(false))
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useEffect(() => {
    initialFetchWedding()
    startUpdateTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const fetchWedding = () => {
    WeddingService().getWedding(WEDDING_ID)
      .then((resp: WeddingResponse) => setWedding(resp.data))
  }

  const startUpdateTimer = () => {
    setInterval(() => {
      fetchWedding()
    }, 8000)
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false
    },
    animation: {
      duration: 0
    },
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          stepSize: 5,
          beginAtZero: true
        },
        font: {
          size: 14
        }
      }],
      x: {
        ticks: {
          font: {
            size: 14
          }
        }
      }
    }
  }

  const data = {
    labels: ["Approved", "Objected"],
    datasets: [{
      data: [wedding?.totalApprovals, wedding?.totalObjections, 0],
      backgroundColor: ['#e8bb7d', '#141414'],
      borderColor: ['#e8bb7d', '#141414'],
      borderWidth: 1,
      borderRadius: 0,
    }]
  }

  return fetchingWedding ?
    <div class={style.loadingWrapper}>
      <div>
        <div class={style.loadingBar}></div>
        <div class={style.loadingBar}></div>
        <div class={style.loadingBar}></div>
        <div class={style.loadingBar}></div>
      </div>
    </div>
    :
    <Fragment>
      <Bar data={data} options={options} height={420} width={400} />
    </Fragment>
}

export default Chart;
