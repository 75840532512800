/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionalComponent, h } from 'preact';
import style from './style.css';
import Chart from './Chart/Chart';

const Results: FunctionalComponent = () => {
  return (
    <div class={style.resultsWrapper} >
      <h2>Results</h2>
      <Chart />
    </div>
  )
}

export default Results;
