/* eslint-disable @typescript-eslint/no-use-before-define */

import {
  VoteRequest,
  VoteResponse,
  CreateWeddingMessageRequest,
  WeddingMessageResponse,
  WeddingResponse,
  WeddingMessagesResponse,
} from "./reqres";
import { BASE_API_URL } from "../../config";
import { post, get } from "../provider";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function WeddingService() {
  return Object.freeze({
    getWedding(weddingId: string): Promise<WeddingResponse> {
      return get(`${BASE_API_URL()}/weddings/${weddingId}`)
    },
    vote(payload: VoteRequest): Promise<VoteResponse> {
      return post(`${BASE_API_URL()}/weddings/vote`, payload)
    },
    resetVotes(weddingId: string): Promise<VoteResponse> {
      return post(`${BASE_API_URL()}/weddings/reset-votes`, { id: weddingId })
    },
    createWeddingMessage(payload: CreateWeddingMessageRequest): Promise<WeddingMessageResponse> {
      return post(`${BASE_API_URL()}/weddings/${payload.weddingId}/messages`, payload)
    },
    listWeddingMessages(weddingId: string): Promise<WeddingMessagesResponse> {
      return get(`${BASE_API_URL()}/weddings/${weddingId}/messages`)
    }
  });
}
