/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FunctionalComponent, h } from "preact";
import style from "./style.css";

type ButtonType = "primary" | "secondary" | "clear" | "danger" | "disabled";

type ButtonHtmlType = "submit" | "reset" | "button";

export interface ButtonProps {
  children?: string;
  buttonType?: ButtonType;
  onClick?: Function;
  htmlType?: ButtonHtmlType;
  loading?: boolean;
  buttonClass?: string;
}

const buttonCssClass = (buttonType: ButtonType): string => {
  switch (buttonType) {
    case "primary":
      return style.primaryButton;
    case "secondary":
      return style.secondaryButton;
    case "clear":
      return style.clearButton;
    case "danger":
      return style.dangerButton;
    case "disabled":
      return style.disabledButton;
  }
}

const Button: FunctionalComponent<ButtonProps> = ({ children, buttonType = "primary", onClick, htmlType = "button", loading = false, buttonClass }: ButtonProps) => {
  if (loading) {
    return (
      <button
        type={htmlType}
        onClick={e => onClick ? onClick(e) : null}
        class={`${buttonCssClass(buttonType)} ${buttonClass}`}
        disabled={buttonType == "disabled"}>
        <div>
          <div class={style.loadingBar}></div>
          <div class={style.loadingBar}></div>
          <div class={style.loadingBar}></div>
          <div class={style.loadingBar}></div>
        </div>
      </button>
    )
  }

  return (
    <button
      type={htmlType}
      onClick={(e) => onClick ? onClick(e) : null}
      class={`${buttonCssClass(buttonType)} ${buttonClass}`}
      disabled={buttonType == "disabled"}>
      {children}
    </button>
  )
}

export default Button;