/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FunctionalComponent, h, Fragment } from 'preact';
import { Step } from '../app';
import style from './style.css';

interface BulletStepsProps {
  steps: Step[];
  currentStep?: string;
}

const BulletSteps: FunctionalComponent<BulletStepsProps> = ({ steps, currentStep }) => {
  const isPastStep = (stepIndex: number): boolean => {
    const currentStepIdx = steps.findIndex(s => s === currentStep)
    return currentStepIdx > stepIndex
  }

  return (
    <nav class={style.bulletStepsWrapper} aria-label="Progress">
      <ol>
        {steps.map((step, idx) => {
          return (
            <li>
              <div class={`${step === currentStep ? style.currentStep : isPastStep(idx) ? style.pastStep : style.step}`}>
                {step === currentStep &&
                  <Fragment>
                    <span class={style.currentStepIndicatorWrapper} aria-hidden="true">
                      <span class={style.currentStepIndicator} />
                    </span>
                    <span class={style.currentStepIndicatorHelper} aria-hidden="true" />
                  </Fragment>}
                <span class={style.stepNumber}>Step {`${idx + 1}`}</span>
              </div>
            </li>
          )
        })}
      </ol>
    </nav>

  );
};

export default BulletSteps;
