/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FunctionalComponent, h } from 'preact';
import { Route, Router } from 'preact-router';
import { useState } from 'preact/hooks';

import BulletSteps from './BulletSteps/BulletSteps';

import Home from '../routes/Home/Home';
import Video from '../routes/Video/Video';
import Vote from '../routes/Vote/Vote';
import Results from '../routes/Results/Results';
import Messages from '../routes/Messages/Messages';

import NotFoundPage from '../routes/NotFound/NotFound';
import { RoutePath } from '../models/route';
import Message from '../routes/Message/Message';
import Reset from '../routes/Reset/Reset';

const steps: Step[] = [RoutePath.Home, RoutePath.Video, RoutePath.Vote, RoutePath.Message, RoutePath.Results]

export type Step = RoutePath

const App: FunctionalComponent = () => {
  const [currentStep, setCurrentStep] = useState<Step | undefined>((typeof window !== "undefined") ? window.location.pathname as Step : undefined)

  const handleSetCurrentStep = (args: any) => {
    setCurrentStep(args.current.props.path as Step)
  }

  return (
    <div id="preact_root" class="app-layout">
      <Router onChange={handleSetCurrentStep}>
        <Route path={RoutePath.Home} component={Home} />
        <Route path={RoutePath.Video} component={Video} />
        <Route path={RoutePath.Vote} component={Vote} />
        <Route path={RoutePath.Message} component={Message} />
        <Route path={RoutePath.Results} component={Results} />
        <Route path={RoutePath.Messages} component={Messages} />
        <Route path={RoutePath.Reset} component={Reset} />
        <NotFoundPage default />
      </Router>
      {currentStep !== RoutePath.Results &&
        <div style={{ marginTop: '50px' }}>
          <BulletSteps steps={steps} currentStep={currentStep} />
        </div>}
    </div>
  );
};

export default App;
