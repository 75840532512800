import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import Button from '../../components/Button/Button';
import { RoutePath } from '../../models/route';
import style from './style.css';

const Video: FunctionalComponent = () => {
  return (
    <div class={style.viewWrapper}>
      <div class={style.video}>
        <video src="https://res.cloudinary.com/dhy0n6q3b/video/upload/v1621109717/VIDEO-2021-05-14-07-33-20_p6rinn.mp4" controls autoPlay />
      </div >
      <div class={style.actionsWrapper}>
        <Button onClick={() => route(RoutePath.Vote, true)} buttonClass={style.continueButton}>Continue</Button>
        <Button buttonType="secondary" onClick={() => route(RoutePath.Home, true)}>Back</Button>
      </div>
    </div >
  );
};

export default Video;
